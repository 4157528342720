import store from '@/store'
import financialStoreModule from '@/pagsmile/financialStoreModule'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'

export default function paymentsJs() {
  const FINANCIAL_STORE_MODULE_NAME = 'financial'
  const MERCHANT_STORE_MODULE_NAME = 'merchant'

  // Register module
  if (!store.hasModule(FINANCIAL_STORE_MODULE_NAME)) store.registerModule(FINANCIAL_STORE_MODULE_NAME, financialStoreModule)
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)

  const fetchMerchants = (success, fail) => {
    store
      .dispatch('merchant/fetchDropDownMerchants')
      .then(response => {
        const { code, msg, data } = response.data
        const merchants = []
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })

          success(merchants)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchMerchantApps = (merchantNo, success, fail) => {
    store
      .dispatch('merchant/fetchDropDownAppsMerchant', merchantNo)
      .then(response => {
        const { code, msg, data } = response.data
        const apps = []
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })

          success(apps)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchChannelFilters = (success, fail) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchPayChannelListDetail = (success, fail) => {
    store
      .dispatch('merchant/fetchPayChannelListDetail')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const searchPayments = (params, success, fail) => {
    store
      .dispatch('financial/fetchPayments', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadPayments = (params, success, fail) => {
    store
      .dispatch('financial/downloadPayments', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const genFinancialReport = (params, success, fail) => {
    store
      .dispatch('financial/genFinancialReport', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchDownloadList = (success, fail) => {
    store
      .dispatch('financial/fetchDownloadList')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFilters,
    fetchPayChannelListDetail,
    searchPayments,
    downloadPayments,
    fetchDownloadList,
    genFinancialReport,
  }
}
