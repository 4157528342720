<template>
  <div class="m-2">
    <b-card title="Transaction Basic Info">
      <b-row>
        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Transaction ID :
            </dt>
            <dd class="col-sm-7">
              {{ payment.trade_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Merchant Reference :
            </dt>
            <dd class="col-sm-7">
              {{ payment.out_trade_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Method :
            </dt>
            <dd class="col-sm-7">
              {{ payment.method }}
            </dd>
          </dl>
        </b-col>

        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Status :
            </dt>
            <dd class="col-sm-7">
              {{ payment.trade_status }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Order Amount :
            </dt>
            <dd class="col-sm-7">
              {{ payment.order_currency }} {{ payment.order_amount }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Pay Amount :
            </dt>
            <dd class="col-sm-7">
              {{ payment.pay_currency }} {{ payment.pay_amount }}
            </dd>
          </dl>
        </b-col>

        <b-col md="4">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Create Time :
            </dt>
            <dd class="col-sm-7">
              {{ timeZoneFilter(payment.create_time) }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Update Time :
            </dt>
            <dd class="col-sm-7">
              {{ timeZoneFilter(payment.update_time) }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Channel ID :
            </dt>
            <dd class="col-sm-7">
              {{ payment.channel_id }}
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col md="6">
        <b-card title="Merchant">
          <dl class="row">
            <dt class="col-sm-4">
              Merchant Name :
            </dt>
            <dd class="col-sm-8">
              {{ payment.merchant_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Merchant NO. :
            </dt>
            <dd class="col-sm-8">
              {{ payment.merchant_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP Name :
            </dt>
            <dd class="col-sm-8">
              {{ payment.app_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP ID :
            </dt>
            <dd class="col-sm-8">
              {{ payment.app_id }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Product">
          <dl class="row">
            <dt class="col-sm-4">
              Subject :
            </dt>
            <dd class="col-sm-8">
              {{ payment.subject }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Content :
            </dt>
            <dd class="col-sm-8">
              {{ payment.content }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Merchant Fees">
          <dl class="row">
            <dt class="col-sm-8">
              Total :
            </dt>
            <dd class="col-sm-4">
              {{ payment.processing_fee_sum }}
            </dd>
          </dl>

          <el-divider class="mt-1 mb-1" />

          <dl v-for="item in payment.processing_fees" :key="item.fee_name" class="row">
            <dt class="col-sm-8">
              {{ item.fee_name }} :
            </dt>
            <dd class="col-sm-4">
              {{ item.fee_amount }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Channel Fees">
          <dl class="row">
            <dt class="col-sm-8">
              Total :
            </dt>
            <dd class="col-sm-4">
              {{ payment.channel_fee_sum }}
            </dd>
          </dl>

          <el-divider class="mt-1 mb-1" />

          <dl v-for="item in payment.channel_fees" :key="item.fee_name" class="row">
            <dt class="col-sm-8">
              {{ item.fee_name }} :
            </dt>
            <dd class="col-sm-4">
              {{ item.fee_amount }}
            </dd>
          </dl>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  filters: {
    timestampFilter(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeZone: this.$route.query.zone_id,
    }
  },
  methods: {
    timeZoneFilter(dt) {
      if (!dt) {
        return ''
      }
      return moment.tz(moment.tz(dt, 'UTC'), this.timeZone).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
