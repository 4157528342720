<template>

  <div>
    <!-- Filters -->
    <payments-list-filters
      @search="search"
      @download="download"
      @gen-financial-report="genFinancialReport"
    />

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="payments"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(trade_no)="data">
          <b-link
            class="font-weight-bold d-block"
            @click="showPaymentDetail(data.item)"
          >
            {{ data.item.trade_no }}
          </b-link>
        </template>

        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ data.item.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(trade_status)="data">
          <b-badge
            pill
            :variant="`${resolveStatusVariant(data.item.trade_status)}`"
          >
            {{ data.item.trade_status }}
          </b-badge>
        </template>

        <template #cell(order_amount)="data">
          {{ data.item.order_currency }} {{ data.item.order_amount }}
        </template>

        <template #cell(pay_amount)="data">
          {{ data.item.pay_currency }} {{ data.item.pay_amount }}
        </template>

        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>

        <template #cell(update_time)="data">
          {{ dateFormat(data.item.update_time) }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-overlay
      :show="loading"
      no-wrap
    />

    <b-modal
      :visible="paymentDetailActive"
      title="Payment Detail"
      size="xl"
      hide-footer
      centered
      @change="(val) => paymentDetailActive = val"
    >
      <payment-detail-view :payment="paymentData" />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BBadge, BOverlay, BLink,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaymentsListFilters from './PaymentsListFilters.vue'
import PaymentDetailView from './PaymentDetailView.vue'
import usePaymentsJs from './payments'

const {
  searchPayments,
  downloadPayments,
  genFinancialReport,
} = usePaymentsJs()

export default {
  components: {
    PaymentsListFilters,
    PaymentDetailView,

    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BOverlay,
    BLink,
  },
  data() {
    return {
      loading: false,
      searchRequest: {},
      payments: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      transactionsTableFields: [
        { key: 'trade_no', label: 'TRANSACTION ID' },
        { key: 'out_trade_no', label: 'MERCHANT REFERENCE' },
        'merchant',
        'app',
        { key: 'create_time', label: 'ORDER TIME' },
        { key: 'update_time', label: 'UPDATE TIME' },
        { key: 'order_amount', label: 'ORDER AMOUNT' },
        { key: 'pay_amount', label: 'PAY AMOUNT' },
        { key: 'method', label: 'METHOD' },
        { key: 'trade_status', label: 'STATUS' },
      ],
      currentPage: 1,
      totalPayments: 0,
      tzSelected: 'Etc/GMT',
      paymentDetailActive: false,
      paymentData: {},
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  created() {
  },
  methods: {
    showToast(title, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: 'BellIcon',
            text,
            variant: 'warning',
          },
        },
        {
          position: 'top-center',
        },
      )
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    resolveStatusVariant(status) {
      if (status === 'Success') return 'success'
      if (status === 'ChargeBack') return 'danger'
      if (status === 'Refunded') return 'danger'
      if (status === 'ChargebackReversed') return 'success'
      return 'secondary'
    },
    search(params) {
      this.loading = true
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      searchPayments(
        this.searchRequest,
        payments => {
          this.payments = payments.list
          this.totalPayments = payments.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalPayments - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalPayments
          this.dataMeta.of = this.totalPayments
          this.loading = false
        },
        fail => {
          this.showToast('Warning', `Fetch Payments with ${fail}`)
          this.loading = false
        },
      )
    },
    download(params) {
      this.searchRequest = params
      downloadPayments(
        this.searchRequest,
        () => {
          this.$router.push({ name: 'payments-download' })
        },
        fail => {
          this.showToast('Warning', `Download failed with ${fail}`)
        },
      )
    },
    genFinancialReport(params) {
      this.searchRequest = params
      genFinancialReport(
        this.searchRequest,
        () => {
          this.$router.push({ name: 'payments-download' })
        },
        fail => {
          this.showToast('Warning', `Gen failed with ${fail}`)
        },
      )
    },
    showPaymentDetail(payment) {
      this.paymentData = payment
      this.paymentDetailActive = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
